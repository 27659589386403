// import React from "react"
// import { MenuProvider } from "./src/components/MenuContext"
// import { AnimatePresence } from "framer-motion"


// export function wrapPageElement({ element }) {
//   return <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
// }

// export function wrapRootElement({ element }) {
//   return <MenuProvider>{element}</MenuProvider>
// }

// export const shouldUpdateScroll = ({ routerProps: { location },
//   getSavedScrollPosition,
// }) => {

//   // if (location.hash) {
//   //   setTimeout(() => {
//   //     document.querySelector(`${location.hash}`).scrollIntoView();
//   //   }, 0);
//   // }

//   if (location.hash) {
//     return false;
//   }

//   // Transition duration from layout.js * 1000 to get ms
//   // * 2 for exit + enter animation/
//   const TRANSITION_DELAY = 0.5 * 1000 * 2

//   // If it is a normal route
//   if (location.action === "PUSH") {
//     window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
//   }

//   // If we used the browsers forward or back button
//   else {
//     const savedPosition = getSavedScrollPosition(location) || [0, 0]

//     window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
//   }

//   return true;

// }


//  utilities
import browserHydrateFunction from './src/utilities/gatsby/browser-hydrate-function';


import React from "react"
import { MenuProvider } from "./src/components/MenuContext"
import { AnimatePresence } from "framer-motion"

export function wrapPageElement({ element }) {
  return <AnimatePresence mode='wait'>{element}</AnimatePresence>
}

export function wrapRootElement({ element }) {
  return <MenuProvider>{element}</MenuProvider>
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Transition duration from layout.js * 1000 to get ms
  // * 2 for exit + enter animation
  const TRANSITION_DELAY = 0.5 * 1000 * 2

  // If it is a normal route
  if (location.action === "PUSH") {

    if (typeof window !== "undefined") {
      window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
    }
    
  }

  // If we used the browsers forward or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]

    if (typeof window !== "undefined") {
      window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
    }
    
  }

  return false
}

export const replaceHydrateFunction = browserHydrateFunction;